import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  FormOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  TagOutlined,
  LogoutOutlined,
  ProductOutlined,
  FileDoneOutlined,
  SettingOutlined,
  BlockOutlined,
  CarryOutOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  AuditOutlined,
  PieChartOutlined,
  FileUnknownOutlined,
  BarsOutlined,
} from "@ant-design/icons";

import { Button, Layout, Menu, theme, Breadcrumb, Form, Grid } from "antd";
import Dashboard from "../pages/Dashboard";
import Ticket from "../pages/Ticket";
import TicketList from "../pages/TicketList";
import Openticket from "../pages/Openticket";
import Myticket from "../pages/Myticket";
import Users from "../pages/Users";
import Login from "../pages/login";
import Homepage from "../pages/Homepage";
import Category from "../pages/Category";
import Tags from "../pages/Tags";
import Department from "../pages/Department";
import Fiscalyear from "../pages/Fiscalyear";
import Place from "../pages/Place";
import Role from "../pages/Role";
import Profile from "../pages/Profile";
import Print from "../pages/Print";
import { logout } from "../redux/authSlice";
import { useSelector, useDispatch } from "react-redux";

const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;
const { SubMenu } = Menu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgLayout, borderRadiusLG },
  } = theme.useToken();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRole = JSON.parse(localStorage.getItem("user"));
  const userType = userRole.user_type;

  useEffect(() => {
    const isSmallScreen = !screens.md;
    setCollapsed(isSmallScreen);
  }, [screens.md]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const getMenuItems = () => {
    switch (userType) {
      case 2:
        return (
          <>
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
              <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<TeamOutlined />}>
              <Link to="/users">Users</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<ProductOutlined />}>
              <Link to="/category">Category</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<BarsOutlined />}>
              <Link to="/ticketlist">Ticket list</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<FileUnknownOutlined />}>
              <Link to="/ticketlog">Ticket log</Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<SettingOutlined />} title="Settings">
              <Menu.Item key="7" icon={<BlockOutlined />}>
                <Link to="/department">Department</Link>
              </Menu.Item>
              <Menu.Item key="8" icon={<CarryOutOutlined />}>
                <Link to="/fiscalyear">Fiscal Year</Link>
              </Menu.Item>
              <Menu.Item key="9" icon={<EnvironmentOutlined />}>
                <Link to="/place">Place</Link>
              </Menu.Item>
              <Menu.Item key="10" icon={<AuditOutlined />}>
                <Link to="/role">Role</Link>
              </Menu.Item>
            </SubMenu>
          </>
        );
      case 1:
        return (
          <>
            <Menu.Item key="11" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="12" icon={<UserOutlined />}>
              <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="13" icon={<FormOutlined />}>
              <Link to="/ticket">Open Ticket</Link>
            </Menu.Item>
            <Menu.Item key="14" icon={<UnorderedListOutlined />}>
              <Link to="/ticketlist">Ticket List</Link>
            </Menu.Item>
          </>
        );
      case 3:
        return (
          <>
            <Menu.Item key="15" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="16" icon={<UserOutlined />}>
              <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="17" icon={<FileDoneOutlined />}>
              <Link to="/myticket">New Ticket</Link>
            </Menu.Item>
            <Menu.Item key="18" icon={<UnorderedListOutlined />}>
              <Link to="/ticketlist">Ticket List</Link>
            </Menu.Item>
            <Menu.Item key="19" icon={<ProductOutlined />}>
              <Link to="/category">Category</Link>
            </Menu.Item>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="px-10 py-4">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Emblem_of_Nepal.svg/1200px-Emblem_of_Nepal.svg.png"
            alt="logo"
          />
        </div>
        <p className="text-white font-bold text-lg text-center pb-4">
          IEMIS Support System
        </p>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          {getMenuItems()}
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="flex justify-between items-center"
          style={{ padding: 0, background: "#eeeeee" }}
        >
          <div className="flex">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={handleToggle}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <div className="flex my-auto mx-auto">
              <img
                src={userRole.photo}
                alt="profile"
                className="rounded-full h-12 w-12"
              />
            </div>
          </div>
          <div className="flex">
            <a href="/" target="_blank" rel="noopener noreferrer">
              <Button type="primary" icon={<GlobalOutlined />} className="mr-6">
                Goto site
              </Button>
            </a>
            <div>
              <Button
                type="primary"
                icon={<LogoutOutlined />}
                className="mr-6"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 820,
            background: colorBgLayout,
            borderRadius: borderRadiusLG,
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/ticket" element={<Ticket />} />
            <Route path="/ticketlist" element={<TicketList />} />
            <Route path="/ticketlist/open/:ticketId" element={<Openticket />} />
            <Route path="/myticket" element={<Myticket />} />
            <Route path="/users" element={<Users />} />
            <Route path="/category" element={<Category />} />
            <Route path="/tags" element={<Tags />} />
            <Route path="/department" element={<Department />} />
            <Route path="/fiscalyear" element={<Fiscalyear />} />
            <Route path="/place" element={<Place />} />
            <Route path="/role" element={<Role />} />
            <Route path="/print" element={<Print />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
