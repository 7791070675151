import React, { useEffect, useState } from "react";
import { Button, Form, Input, Upload, Card, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, editUserProfile } from "../redux/userSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Profile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        phone: user.phone || "",
        user_type: user.user_type || "",
        permanent_place: user.permanent_place || "",
        permanent_address: user.permanent_address || "",
        current_place: user.current_place || "",
        current_address: user.current_address || "",
        id_no: user.id_no || "",
      });
      setPhoto(
        user.photo
          ? `https://eticketing.waveplus.com.np/web/media/user_photos/${user.photo
              .split("/")
              .pop()}`
          : ""
      );
    }
  }, [user, form]);

  const handlePhotoChange = (info) => {
    console.log("working");
    if (info.file.status === "done") {
      const newPhoto = URL.createObjectURL(info.file.originFileObj);
      setPhoto(newPhoto);
      console.log("New photo URL:", newPhoto);
    } else if (info.file.status === "error") {
      console.error("File upload failed");
    }
  };

  const handleFinish = async (values) => {
    const { photo, ...formValues } = values;
    const updatedUser = { ...formValues, photo };

    try {
      await dispatch(editUserProfile(updatedUser)).unwrap();
      const userData = await dispatch(fetchUser()).unwrap();
      localStorage.setItem("user", JSON.stringify(userData));
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  return (
    <Card
      title={
        <div className="flex items-center justify-between">
          <span>Edit Profile</span>
          <img
            src={photo}
            alt="Profile Icon"
            className="w-12 h-12 my-2 rounded-full"
          />
        </div>
      }
    >
      <div className="">
        <Form
          {...formItemLayout}
          layout="vertical"
          form={form}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
          onFinish={handleFinish}
        >
          <Form.Item label="Photo">
            <Upload
              beforeUpload={() => false} // Prevent auto upload
              onChange={handlePhotoChange}
            >
              <Button icon={<UploadOutlined />}>Change Profile Photo</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="User Type"
            name="user_type"
            rules={[{ required: true, message: "Please input your phone!" }]}
            className="col-span-1"
          >
            <Input placeholder="Enter user Type" />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Please input your phone!" }]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Form.Item
            label="Permanent Place"
            name="permanent_place"
            rules={[
              { required: true, message: "Please input your permanent place!" },
            ]}
          >
            <Input placeholder="Enter permanent place" />
          </Form.Item>

          <Form.Item
            label="Permanent Address"
            name="permanent_address"
            rules={[
              {
                required: true,
                message: "Please input your permanent address!",
              },
            ]}
          >
            <Input placeholder="Enter permanent address" />
          </Form.Item>

          <Form.Item
            label="Current Place"
            name="current_place"
            rules={[
              { required: true, message: "Please input your current place!" },
            ]}
          >
            <Input placeholder="Enter current place" />
          </Form.Item>

          <Form.Item
            label="Current Address"
            name="current_address"
            rules={[
              { required: true, message: "Please input your current address!" },
            ]}
          >
            <Input placeholder="Enter current address" />
          </Form.Item>

          <Form.Item
            label="ID Number"
            name="id_no"
            rules={[
              { required: true, message: "Please input your ID number!" },
            ]}
          >
            <Input placeholder="Enter ID number" />
          </Form.Item>
        </Form>
        <div className="flex justify-start mt-4">
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Edit Profile
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
