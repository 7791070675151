import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Space,
  Table,
  Form,
  Input,
  Button,
  Select,
  Upload,
  Card,
  Row,
  Col,
  Modal,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleFilled,
  AntDesignOutlined,
} from "@ant-design/icons";
import { fetchDepartment, submitDepartment } from "../redux/departmentSlice";
import { Link } from "react-router-dom";
const { confirm } = Modal;

const Department = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const department = useSelector((state) => state.department.department);

  const showConfirm = () => {
    confirm({
      title: "Do you want to add departments?",
      icon: <ExclamationCircleFilled />,
      content: "Department",
      onOk() {
        handleSubmit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(submitDepartment(values));
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    dispatch(fetchDepartment());
  }, [dispatch]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space size="middle">
          <Button type="primary" size="medium" icon={<AntDesignOutlined />}>
            Edit
          </Button>
          <Link to="/ticketlist/open">
            <Button type="default" size="medium" icon={<AntDesignOutlined />}>
              Delete
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="flex flex-col gap-8"
        style={{ maxWidth: 1700 }}
      >
        <Card title="Department">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please input your category name" },
            ]}
          >
            <Input className="py-1" />
          </Form.Item>
          <Button type="primary" className="py-3 text-sm" onClick={showConfirm}>
            add department
          </Button>
        </Card>
      </Form>
      <Table
        className="mt-4"
        dataSource={department}
        columns={columns}
        rowKey="name"
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default Department;
