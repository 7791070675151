import React, { useEffect, useState } from "react";
import axios from "axios";

const Print = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchFiscalYears = async () => {
      try {
        const response = await axios.get(
          "https://imagepasal.com/wp-json/wl/v1/products-category/191"
        );
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFiscalYears();
  }, []);
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {data.map((item) => (
        <div key={item.id} className="border p-4">
          <img src={item.images} alt={item.name} className="w-full h-auto" />
          <h2 className="text-lg font-semibold mt-2">{item.name}</h2>
          <p>Price: {item.price}</p>
          <p>Printable: {item.printable ? "Yes" : "No"}</p>
        </div>
      ))}
    </div>
  );
};

export default Print;
