import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitTicket, resetState } from "../redux/ticketslice";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Space,
  Card,
  Row,
  Col,
  Modal,
  notification,
} from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { fetchCategories } from "../redux/categorySlice";

const { Option } = Select;
const { confirm } = Modal;

const Ticket = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.ticket);
  const categories = useSelector((state) => state.category.categories);

  const [form] = Form.useForm();

  const showConfirm = () => {
    confirm({
      title: "Do you want to Generate the support ticket?",
      icon: <ExclamationCircleFilled />,
      content: "your status will be notified through email",
      onOk() {
        handleSubmit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(submitTicket(values));
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    if (success) {
      notification.success({
        message: "Success",
        description: "Your support ticket has been submitted successfully!",
      });
      form.resetFields();
      dispatch(resetState());
    }
  }, [success, dispatch]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  console.log(categories);

  return (
    <Form
      form={form}
      layout="vertical"
      className="flex flex-col gap-8"
      style={{ maxWidth: 1700 }}
    >
      <Card title="Ticket information">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="priority"
              label="priority"
              rules={[{ required: true, message: "Please select a priority!" }]}
            >
              <Select
                placeholder="Choose your priority"
                style={{
                  height: "32px",
                }}
                allowClear
              >
                <Option value="low">Low</Option>
                <Option value="medium">Medium</Option>
                <Option value="high">High</Option>
                <Option value="critical">Critical</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: "Please select a category!" }]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="select category"
              >
                {categories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card title="Message">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input className="py-1" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: "Please input your description!" },
          ]}
        >
          <Input.TextArea style={{ height: "150px" }} />
        </Form.Item>
      </Card>

      {/* <Card title="Attachment">
        <Form.Item
          label="Upload"
          name="attachment"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
        >
          <Upload action="/upload.do" listType="picture-card">
            <button style={{ border: 0, background: "none" }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>
        </Form.Item>
      </Card> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          type="primary"
          loading={loading}
          className="w-full py-6 text-xl"
          onClick={showConfirm}
        >
          Submit
        </Button>
      </Form.Item>

      {success && <p>Ticket submitted successfully!</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </Form>
  );
};

export default Ticket;
