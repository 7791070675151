import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  submitCategory,
  updateCategory,
  deleteCategory,
} from "../redux/categorySlice";
import {
  Space,
  Table,
  Form,
  Input,
  Button,
  Select,
  Upload,
  Card,
  Row,
  Col,
  Modal,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleFilled,
  AntDesignOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Option } = Select;
const { confirm } = Modal;

const Category = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const categories = useSelector((state) => state.category.categories);

  const showConfirm = () => {
    confirm({
      title: "Do you want to add category?",
      icon: <ExclamationCircleFilled />,
      content: "category",
      onOk() {
        handleSubmit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(submitCategory(values));
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space size="middle">
          <Button type="default" size="medium" icon={<AntDesignOutlined />}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="flex flex-col gap-8"
        style={{ maxWidth: 1700 }}
      >
        <Card title="category">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please input your category name" },
            ]}
          >
            <Input className="py-1" />
          </Form.Item>
          <Button type="primary" className="py-3 text-sm" onClick={showConfirm}>
            add Category
          </Button>
        </Card>
      </Form>
      <Table
        className="mt-4"
        dataSource={categories}
        columns={columns}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default Category;
