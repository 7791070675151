import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import api from "../utils/api";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.user_details;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      Cookies.set("access_token", action.payload.accessToken);
      Cookies.set("refresh_token", action.payload.refreshToken);
      localStorage.setItem("user", JSON.stringify(action.payload.user_details));
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      localStorage.removeItem("user");
    },
    registerStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    registerSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.user_details;
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      Cookies.set("access_token", action.payload.accessToken);
      Cookies.set("refresh_token", action.payload.refreshToken);
      localStorage.setItem("user", JSON.stringify(action.payload.user_details));
    },
    registerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  registerStart,
  registerSuccess,
  registerFailure,
} = authSlice.actions;

export const login = (username, password) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const response = await api.post("auth/login/", {
      username,
      password,
    });
    dispatch(
      loginSuccess({
        user_details: response.data.user_details,
        accessToken: response.data.access,
        refreshToken: response.data.refresh,
      })
    );
    return { type: "login/fulfilled", payload: response.data.user };
  } catch (error) {
    dispatch(
      loginFailure(error.response?.data?.message || "An error occurred")
    );
    return { type: "login/rejected", payload: error.message };
  }
};

export const register = (formData) => async (dispatch) => {
  dispatch(registerStart());
  try {
    const response = await api.post("v1/user-mgmt/user-details/", {
      user: {
        username: formData.username,
        password: formData.password,
        confirm_password: formData.confirm_password,
        email: formData.email,
        groups: formData.groups,
      },
      department: formData.department,
      user_type: formData.user_type,
      phone: formData.phone,
      permanent_place: formData.permanent_place,
      permanent_address: formData.permanent_address,
      current_place: formData.current_place,
      current_address: formData.current_address,
      id_no: formData.id_no,
      photo: formData.photo,
      can_solve: formData.can_solve,
    });
    dispatch(
      registerSuccess({
        user_details: response.data.user_details,
        accessToken: response.data.access,
        refreshToken: response.data.refresh,
      })
    );
    return { type: "register/fulfilled", payload: response.data.user };
  } catch (error) {
    dispatch(
      registerFailure(error.response?.data?.message || "An error occurred")
    );
    return { type: "register/rejected", payload: error.message };
  }
};

export default authSlice.reducer;
