import React, { useState, useRef, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Faq = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <>
      <Header />
      <div className="px-14 md:px-24 lg:px-48 pb-40 mt-40">
        <h1 className="text-7xl font-bold font-sans py-2 text-center tracking-tight my-8">
          F<span className="text-[#124e66]">A</span>Q
        </h1>
        <div className="flex justify-between flex-col md:flex-row">
          <div className="bg-[#124e66] py-4 rounded-xl w-full md:w-[300px] h-[300px]">
            <ul className="-mb-px text-sm font-medium p-4" role="tablist">
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "profile"
                      ? "text-white border-white"
                      : "text-gray-black border-black"
                  }`}
                  onClick={() => handleTabClick("profile")}
                  role="tab"
                >
                  Profile faq {activeTab === "profile" ? ">" : ""}
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "dashboard"
                      ? "text-white border-white"
                      : "text-gray-black border-black"
                  }`}
                  onClick={() => handleTabClick("dashboard")}
                  role="tab"
                >
                  Dashboard faq {activeTab === "dashboard" ? ">" : ""}
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "settings"
                      ? "text-white border-white"
                      : "text-gray-black border-black"
                  }`}
                  onClick={() => handleTabClick("settings")}
                  role="tab"
                >
                  Settings faq {activeTab === "settings" ? ">" : ""}
                </button>
              </li>
            </ul>
          </div>

          <div
            className={`w-full p-4 rounded-lg ${
              activeTab === "profile" ? "block" : "hidden"
            }`}
            role="tabpanel"
          >
            <div id="accordion-open">
              <h2 id="accordion-open-heading-1">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md rounded-md mb-4"
                  onClick={() => toggleSection(1)}
                  aria-expanded={openSection === 1}
                  aria-controls="accordion-open-body-1"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    What is Flowbite?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 1 ? "rotate-180" : ""
                    } transition-transform duration-600 shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-1"
                className={`overflow-hidden transition-all duration-600 ease-in-out ${
                  openSection === 1
                    ? "max-h-screen opacity-100"
                    : "max-h-0 opacity-0"
                }`}
                aria-labelledby="accordion-open-heading-1"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is an open-source library of interactive components
                    built on top of Tailwind CSS including buttons, dropdowns,
                    modals, navbars, and more.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Check out this guide to learn how to{" "}
                    <a
                      href="/docs/getting-started/introduction/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      get started
                    </a>{" "}
                    and start developing websites even faster with components on
                    top of Tailwind CSS.
                  </p>
                </div>
              </div>

              {/* Repeat for other sections with similar updates */}
              <h2 id="accordion-open-heading-2">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md rounded-md mb-4"
                  onClick={() => toggleSection(2)}
                  aria-expanded={openSection === 2}
                  aria-controls="accordion-open-body-2"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Is there a Figma file available?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 2 ? "rotate-180" : ""
                    } transition-transform duration-600 shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-2"
                className={`overflow-hidden transition-all duration-600 ease-in-out ${
                  openSection === 2
                    ? "max-h-screen opacity-100"
                    : "max-h-0 opacity-0"
                }`}
                aria-labelledby="accordion-open-heading-2"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is first conceptualized and designed using the
                    Figma software so everything you see in the library has a
                    design equivalent in our Figma file.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Check out the{" "}
                    <a
                      href="https://flowbite.com/figma/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Figma design system
                    </a>{" "}
                    based on the utility classes from Tailwind CSS and
                    components from Flowbite.
                  </p>
                </div>
              </div>

              <h2 id="accordion-open-heading-3">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md rounded-md"
                  onClick={() => toggleSection(3)}
                  aria-expanded={openSection === 3}
                  aria-controls="accordion-open-body-3"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    What are the differences between Flowbite and Tailwind UI?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 3 ? "rotate-180" : ""
                    } transition-transform duration-600 shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-3"
                className={`overflow-hidden transition-all duration-600 ease-in-out ${
                  openSection === 3
                    ? "max-h-screen opacity-100"
                    : "max-h-0 opacity-0"
                }`}
                aria-labelledby="accordion-open-heading-3"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is a free and open-source library of Tailwind CSS
                    components and templates. It’s designed to be used as a
                    building block for your next web project. Tailwind UI, on
                    the other hand, is a commercial product and is not open
                    source.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Learn more about the differences between these two libraries
                    in the{" "}
                    <a
                      href="https://flowbite.com/docs/getting-started/introduction/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Flowbite documentation
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full p-4 rounded-lg ${
              activeTab === "dashboard" ? "block" : "hidden"
            }`}
            role="tabpanel"
          >
            <div id="accordion-open">
              <h2 id="accordion-open-heading-1">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md rounded-md mb-4"
                  onClick={() => toggleSection(1)}
                  aria-expanded={openSection === 1}
                  aria-controls="accordion-open-body-1"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    What is Flowbite?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 1 ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-1"
                className={`${openSection === 1 ? "" : "hidden"}`}
                aria-labelledby="accordion-open-heading-1"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is an open-source library of interactive components
                    built on top of Tailwind CSS including buttons, dropdowns,
                    modals, navbars, and more.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Check out this guide to learn how to{" "}
                    <a
                      href="/docs/getting-started/introduction/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      get started
                    </a>{" "}
                    and start developing websites even faster with components on
                    top of Tailwind CSS.
                  </p>
                </div>
              </div>

              <h2 id="accordion-open-heading-2">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md gap-3 rounded-md mb-4"
                  onClick={() => toggleSection(2)}
                  aria-expanded={openSection === 2}
                  aria-controls="accordion-open-body-2"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Is there a Figma file available?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 2 ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-2"
                className={`${openSection === 2 ? "" : "hidden"}`}
                aria-labelledby="accordion-open-heading-2"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is first conceptualized and designed using the
                    Figma software so everything you see in the library has a
                    design equivalent in our Figma file.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Check out the{" "}
                    <a
                      href="https://flowbite.com/figma/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Figma design system
                    </a>{" "}
                    based on the utility classes from Tailwind CSS and
                    components from Flowbite.
                  </p>
                </div>
              </div>

              <h2 id="accordion-open-heading-3">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md gap-3 rounded-md"
                  onClick={() => toggleSection(3)}
                  aria-expanded={openSection === 3}
                  aria-controls="accordion-open-body-3"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    What are the differences between Flowbite and Tailwind UI?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 3 ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-3"
                className={`${openSection === 3 ? "" : "hidden"}`}
                aria-labelledby="accordion-open-heading-3"
              >
                <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    The main difference is that Flowbite is an open-source
                    library of components built on top of Tailwind CSS, whereas
                    Tailwind UI is a paid product that provides a collection of
                    ready-made components and templates based on Tailwind CSS.
                  </p>
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite focuses on smaller, customizable components, while
                    Tailwind UI offers a more extensive set of components and
                    templates.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Both libraries are designed to work with Tailwind CSS and
                    can be used together in a project.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full p-4 rounded-lg ${
              activeTab === "settings" ? "block" : "hidden"
            }`}
            role="tabpanel"
          >
            <div id="accordion-open">
              <h2 id="accordion-open-heading-1">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md rounded-md mb-4"
                  onClick={() => toggleSection(1)}
                  aria-expanded={openSection === 1}
                  aria-controls="accordion-open-body-1"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    What is Flowbite?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 1 ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-1"
                className={`${openSection === 1 ? "" : "hidden"}`}
                aria-labelledby="accordion-open-heading-1"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is an open-source library of interactive components
                    built on top of Tailwind CSS including buttons, dropdowns,
                    modals, navbars, and more.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Check out this guide to learn how to{" "}
                    <a
                      href="/docs/getting-started/introduction/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      get started
                    </a>{" "}
                    and start developing websites even faster with components on
                    top of Tailwind CSS.
                  </p>
                </div>
              </div>

              <h2 id="accordion-open-heading-2">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md gap-3 rounded-md mb-4"
                  onClick={() => toggleSection(2)}
                  aria-expanded={openSection === 2}
                  aria-controls="accordion-open-body-2"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Is there a Figma file available?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 2 ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-2"
                className={`${openSection === 2 ? "" : "hidden"}`}
                aria-labelledby="accordion-open-heading-2"
              >
                <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite is first conceptualized and designed using the
                    Figma software so everything you see in the library has a
                    design equivalent in our Figma file.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Check out the{" "}
                    <a
                      href="https://flowbite.com/figma/"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Figma design system
                    </a>{" "}
                    based on the utility classes from Tailwind CSS and
                    components from Flowbite.
                  </p>
                </div>
              </div>

              <h2 id="accordion-open-heading-3">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-sans bg-white shadow-md gap-3 rounded-md"
                  onClick={() => toggleSection(3)}
                  aria-expanded={openSection === 3}
                  aria-controls="accordion-open-body-3"
                >
                  <span className="flex items-center text-[#124e66]">
                    <svg
                      className="w-5 h-5 mr-2 shrink-0"
                      fill="#124e66"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    What are the differences between Flowbite and Tailwind UI?
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 text-[#124e66] ${
                      openSection === 3 ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="#124e66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5L5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-open-body-3"
                className={`${openSection === 3 ? "" : "hidden"}`}
                aria-labelledby="accordion-open-heading-3"
              >
                <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    The main difference is that Flowbite is an open-source
                    library of components built on top of Tailwind CSS, whereas
                    Tailwind UI is a paid product that provides a collection of
                    ready-made components and templates based on Tailwind CSS.
                  </p>
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Flowbite focuses on smaller, customizable components, while
                    Tailwind UI offers a more extensive set of components and
                    templates.
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Both libraries are designed to work with Tailwind CSS and
                    can be used together in a project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
