import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux"; // Import Redux Provider
import store from "./redux/store.js"; // Import your Redux store
import Sidebar from "./components/Sidebar";
import { ThemeProvider } from "@material-tailwind/react";
import Homepage from "./pages/Homepage.jsx";
import Login from "./pages/login.jsx";
import Register from "./pages/Register.jsx";
import Faq from "./pages/Faq.jsx";
import Contact from "./pages/Contact.jsx";

function App() {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            {/* Protected routes */}
            <Route path="/*" element={<Sidebar />} />
          </Routes>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
