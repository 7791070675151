import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import ticketReducer from "./ticketslice";
import userReducer from "./userSlice";
import categoryReducer from "./categorySlice";
import tagReducer from "./tagSlice";
import departmentReducer from "./departmentSlice";
import placeReducer from "./placeSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    ticket: ticketReducer,
    users: userReducer,
    category: categoryReducer,
    tags: tagReducer,
    department: departmentReducer,
    place: placeReducer,
  },
});
