import React, { useEffect, useState } from "react";
import { Divider, Radio, Table, Tag, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../redux/userSlice";

const Users = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);
  console.log(users);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];
  return (
    <>
      {loading ? (
        <Skeleton active paragraph={{ rows: 10 }} />
      ) : (
        <Table
          dataSource={users}
          columns={columns}
          rowKey="key"
          scroll={{ x: "max-content" }}
        />
      )}
      {error && <p>Error: {error}</p>}
    </>
  );
};

export default Users;
