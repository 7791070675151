import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Button, Skeleton, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AntDesignOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  fetchTicketsByUser,
  fetchTicketsByStatus,
  fetchTicketsByClient,
  fetchTickets,
} from "../redux/ticketslice";

const TicketList = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.ticket);
  const [acceptedTickets, setAcceptedTickets] = useState([]);
  const [completedTickets, setCompletedTickets] = useState([]);
  const [clientTickets, setClientTickets] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const userRole = JSON.parse(localStorage.getItem("user"));
  const userType = userRole.user_type;

  useEffect(() => {
    if (userType === 3) {
      dispatch(fetchTicketsByUser()).then((response) => {
        console.log("Fetched tickets by user:", response.payload);
        setAcceptedTickets(response.payload);
      });
      dispatch(fetchTicketsByStatus()).then((response) => {
        setCompletedTickets(response.payload);
      });
    } else if (userType === 1) {
      dispatch(fetchTicketsByClient()).then((response) => {
        setClientTickets(response.payload);
      });
    } else if (userType === 2) {
      dispatch(fetchTickets()).then((response) => {
        setAllTickets(response.payload);
      });
    }
  }, [dispatch, userType]);

  const columns = [
    {
      title: "Ticket code",
      dataIndex: "ticket_code",
      key: "ticket_code",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        let color = "";
        switch (priority) {
          case "low":
            color = "green";
            break;
          case "medium":
            color = "blue";
            break;
          case "high":
            color = "orange";
            break;
          case "critical":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{priority.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/ticketlist/open/${record.id}`}>
            <Button type="default" size="medium" icon={<AntDesignOutlined />}>
              Open
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return loading ? (
    <Skeleton active paragraph={{ rows: 10 }} />
  ) : (
    <>
      {userType === 3 && (
        <>
          <div>
            <h2>Accepted Ticket List</h2>
            <Table
              dataSource={acceptedTickets}
              columns={columns}
              scroll={{ x: "max-content" }}
            />
          </div>
          <div>
            <h2>Completed Ticket List</h2>
            <Table
              dataSource={completedTickets}
              columns={columns}
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}
      {userType === 1 && (
        <div>
          <h2>Ticket List</h2>
          <Table
            dataSource={clientTickets}
            columns={columns}
            scroll={{ x: "max-content" }}
          />
        </div>
      )}
      {userType === 2 && (
        <div>
          <Table
            dataSource={allTickets}
            columns={columns}
            scroll={{ x: "max-content" }}
          />
        </div>
      )}

      {error && (
        <div style={{ color: "red" }}>
          {typeof error === "object" ? JSON.stringify(error) : error}
        </div>
      )}
    </>
  );
};

export default TicketList;
