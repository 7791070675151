import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  Card,
  TreeSelect,
  Divider,
  Upload,
  Row,
  Col,
  Image,
  Avatar,
  List,
  Space,
  Collapse,
  Skeleton,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchTicketById,
  updateTicketStatus,
  fetchCommentsByTicketId,
} from "../redux/ticketslice";
import { fetchDepartment } from "../redux/departmentSlice";
import { submitComment } from "../redux/ticketslice";

const { RangePicker } = DatePicker;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Openticket = () => {
  const { ticketId } = useParams();
  const dispatch = useDispatch();
  const { ticketDetails, loading, error } = useSelector(
    (state) => state.ticket
  );
  const department = useSelector((state) => state.department.department);
  const comments = useSelector((state) => state.ticket.comments);

  useEffect(() => {
    if (ticketId) {
      dispatch(fetchTicketById(ticketId));
      dispatch(fetchCommentsByTicketId(ticketId));
    }
    dispatch(fetchDepartment());
  }, [dispatch, ticketId]);

  console.log("Loading:", loading);
  console.log("Error:", error);
  console.log("Ticket Details from State:", ticketDetails);

  const handleChangeStatus = (newStatus) => {
    if (newStatus !== ticketDetails.status) {
      dispatch(updateTicketStatus({ ticketId, status: newStatus }))
        .then((response) => {
          if (response.error) {
            console.error("Failed to update status:", response.error);
          } else {
            dispatch(fetchTicketById(ticketId));
          }
        })
        .catch((error) => {
          console.error("Update status failed:", error);
        });
    }
  };

  const handleSubmitComment = (values) => {
    console.log("Form values", values);
    const { comment } = values;
    console.log("Comment Value:", comment);
    if (comment) {
      dispatch(submitComment({ ticketId, commentData: { comment: comment } }))
        .then((response) => {
          if (response.error) {
            console.error("Failed to submit comment:", response.error);
          } else {
            dispatch(fetchCommentsByTicketId(ticketId));
          }
        })
        .catch((error) => {
          console.error("Submit comment failed:", error);
        });
    }
  };

  const handleAssignDepartment = () => {
    console.log("ok");
  };

  if (loading) return <Skeleton active />;
  if (error) return <div>{error}</div>;
  if (!ticketDetails) return <div>No ticket details available</div>;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <Card title={ticketDetails.ticket_code}>
            <div className="flex gap-4 flex-col">
              <div>
                <h1 className="text-md font-semibold ">Title</h1>
                <p className=" text-sm font-mono">{ticketDetails.title}</p>
              </div>
              <div>
                <h1 className="text-md font-semibold ">Description</h1>
                <p className="text-sm font-mono">{ticketDetails.description}</p>
              </div>
            </div>
            <Divider style={{ borderColor: "#7cb305" }}>Change Status</Divider>
            <Form
              {...formItemLayout}
              variant="filled"
              style={{ maxWidth: 1700 }}
            >
              <Form.Item
                name="Status"
                label="Status"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={ticketDetails.status}
                  allowClear
                  onChange={handleChangeStatus}
                >
                  <Option value="open">Open</Option>
                  <Option value="in_progress">In Progress</Option>
                  <Option value="onhold">Onhold</Option>
                  <Option value="resolved">Resolved</Option>
                  <Option value="closed">Closed</Option>
                  <Option value="reopened">Reopened</Option>
                  <Option value="canceled">Canceled</Option>
                  <Option value="unresolved">Unresolved</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="Assign to"
                label="assign"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="choose department"
                  allowClear
                  onChange={handleAssignDepartment}
                >
                  {department.map((dept) => (
                    <Option key={dept.name} value={dept.name}>
                      {dept.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <div className="flex">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 8 }}
                  >
                    Confirm
                  </Button>
                  <Button type="default" htmlType="submit">
                    cancel
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {/* <Divider style={{ borderColor: "#7cb305" }}></Divider>

            <Form.Item
              name="department"
              label="department"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose your department" allowClear>
                <Option value="1">department 1</Option>
                <Option value="2">department 2</Option>
                <Option value="3">department 3</Option>
              </Select>
            </Form.Item>
            <Divider style={{ borderColor: "#7cb305" }}></Divider>
            <Form.Item
              name="priority"
              label="priority"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Select placeholder="choose your priority" allowClear>
                <Option value="low">Low</Option>
                <Option value="medium">Medium</Option>
                <Option value="high">High</Option>
                <Option value="critcal">Critical</Option>
              </Select>
            </Form.Item>
            <Divider style={{ borderColor: "#7cb305" }}></Divider>
            <Form.Item
              name="assigned to"
              label="assigned to"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose your department" allowClear>
                <Option value="1">User 1</Option>
                <Option value="2">User 2</Option>
                <Option value="3">User 3</Option>
              </Select>
            </Form.Item>
            <Divider style={{ borderColor: "#7cb305" }}></Divider> */}
          </Card>
        </Col>
        <Col xs={24} md={17} className="flex flex-col gap-4">
          <Collapse
            className="bg-white"
            items={[
              {
                key: "1",
                label: "Reply",
                children: (
                  <Form onFinish={handleSubmitComment}>
                    <Row gutter={16} style={{ marginTop: 10 }}>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name="comment"
                          wrapperCol={{ span: 24 }}
                          rules={[{ message: "Please input!" }]}
                        >
                          <Input.TextArea />
                        </Form.Item>
                        {/* <Form.Item
                          label="Attachment"
                          valuePropName="fileList"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          getValueFromEvent={normFile}
                        >
                          <Upload
                            action="/upload.do"
                            listType="picture-card"
                            accept=".jpg,.jpeg,.png,.pdf"
                          >
                            <button
                              style={{ border: 0, background: "none" }}
                              type="button"
                            >
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                          </Upload>
                        </Form.Item> */}
                      </Col>
                      <Col xs={24} md={4}>
                        <Button
                          type="primary"
                          className="w-full text-md my-auto"
                          htmlType="submit"
                        >
                          reply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                ),
              },
            ]}
          />
          <List
            itemLayout="vertical"
            size="large"
            dataSource={comments}
            renderItem={(comment) => (
              <Card style={{ marginBottom: "16px" }}>
                <List.Item key={comment.id}>
                  <List.Item.Meta
                    title={`Comment by ${comment.created_by ?? "Anonymous"}`}
                    description={`Created on: ${new Date(
                      comment.timestamp
                    ).toLocaleString()}`}
                  />
                  <Divider />
                  <span>{comment.comment}</span>
                </List.Item>
              </Card>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default Openticket;
