import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Form,
  Input,
  Button,
  Select,
  Upload,
  Card,
  Row,
  Col,
  Modal,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleFilled,
  AntDesignOutlined,
} from "@ant-design/icons";
import api from "../utils/api";
import { Link } from "react-router-dom";
const { confirm } = Modal;

const Fiscalyear = () => {
  const [form] = Form.useForm();
  const [fiscalyear, setFiscalyear] = useState([]);

  useEffect(() => {
    const fetchFiscalYears = async () => {
      try {
        const response = await api.get("v1/setting/fiscal-years");
        setFiscalyear(response.data.results);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFiscalYears();
  }, []);

  const showConfirm = () => {
    confirm({
      title: "Do you want to add Tags?",
      icon: <ExclamationCircleFilled />,
      content: "Tags",
      onOk() {
        handleSubmit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSubmit = () => {
    console.log("ok");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start At",
      dataIndex: "startAt",
      key: "startAt",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space size="middle">
          <Button type="primary" size="medium" icon={<AntDesignOutlined />}>
            Edit
          </Button>
          <Link to="/ticketlist/open">
            <Button type="default" size="medium" icon={<AntDesignOutlined />}>
              Delete
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="flex flex-col gap-8"
        style={{ maxWidth: 1700 }}
      >
        <Card title="Fiscal year">
          {/* <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please input your category name" },
            ]}
          >
            <Input className="py-1" />
          </Form.Item> */}
          <Button type="primary" className="py-3 text-sm" onClick={showConfirm}>
            add Fiscal Year
          </Button>
        </Card>
      </Form>
      <Table
        className="mt-4"
        dataSource={fiscalyear}
        columns={columns}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default Fiscalyear;
