import React, { useEffect } from "react";
import { Space, Table, Tag, Button, Skeleton } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { fetchTicketsByCategory, acceptTicket } from "../redux/ticketslice";
import { useDispatch, useSelector } from "react-redux";

const Myticket = () => {
  const dispatch = useDispatch();
  const { tickets, loading, error } = useSelector((state) => state.ticket);
  console.log("Tickets in component:", tickets);

  useEffect(() => {
    dispatch(fetchTicketsByCategory()).then((response) => {
      console.log("Fetched tickets by category:", response.payload);
    });
  }, [dispatch]);

  const handleAccept = (ticketId) => {
    dispatch(acceptTicket(ticketId));
    dispatch(fetchTicketsByCategory());
  };

  const columns = [
    {
      title: "Ticket code",
      dataIndex: "ticket_code",
      key: "ticket_code",
    },
    {
      title: "priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        let color = "";
        switch (priority) {
          case "low":
            color = "green";
            break;
          case "medium":
            color = "blue";
            break;
          case "high":
            color = "orange";
            break;
          case "critical":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{priority.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "high") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link>
            <Button
              type="primary"
              size="medium"
              icon={<AntDesignOutlined />}
              onClick={() => handleAccept(record.id)}
            >
              Accept
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
  return loading ? (
    <Skeleton active paragraph={{ rows: 10 }} />
  ) : error ? (
    <p>Error loading tickets</p>
  ) : (
    <Table
      dataSource={tickets}
      columns={columns}
      scroll={{ x: "max-content" }}
    />
  );
};

export default Myticket;
